@import 'common/common.scss';

.container {
    width: 100%;
    overflow-x: hidden;
}

.viewport {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    align-items: center;
    place-content: space-between;
    cursor: grab;
    user-select: none;

    &.idle {
        cursor: auto;
    }
}

.progress {
    position: relative;
    height: rem(12px);
    width: 100%;
    background-color: #ddd;
}

.bar {
    position: absolute;
    height: rem(12px);
    width: 0%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: currentColor;
}
