@import '../../styles/common/common.scss';

.top_container {
    margin-bottom: var(--component-unhook-height);
    padding-bottom: var(--component-unhook-height);
}

.container {
    position: relative;
    padding-top: calc(var(--component-unhook-height) * 2);
    padding-bottom: rem(70px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* Needed for bg blending */
    opacity: 0.99;
    background-color: rgba(204, 204, 204, 0.978);
    /* Needed for bg blending */
    color: var(--utmb-color-white);
    text-shadow: var(--utmb-text-shadows);

    .filtered_img {
        filter: var(--utmb-filter-bgimg) !important;
    }

    @include bp(lg) {
        min-height: unset;
        height: auto;
        padding-bottom: 0;
    }
}

.promo_slider_separator {
    @media (orientation: landscape) {
        max-width: rem(540px) !important;
        width: rem(540px) !important;
        overflow-x: hidden;
    }
}

.stat {
    z-index: -1;
    font-size: 8vmax;
    opacity: 0.6;
    letter-spacing: rem(8px);
    pointer-events: none;
    position: absolute;
    top: -20%;
    left: 0%;
    text-shadow: none;

    @include bp(sm) {
        font-size: 10vmax;
        top: -17%;
        left: 10%;
    }

    @include bp(md) {
        font-size: 12vmax;
        top: -20%;
        left: 40%;
    }

    @include bp(lg) {
        top: -18%;
        left: 60%;
    }

    @include bp(xl) {
        top: -22%;
        left: 70%;
    }
}
.draggable {
    max-width: rem(440px);
    margin-left: auto;
    margin-right: auto;
    @media (orientation: landscape) {
        max-width: rem(540px);
    }
}
.toolbar {
    color: var(--utmb-color-white);
    width: 100%;
    padding: 0 0 rem(44px) 0;
    & > button {
        line-height: rem(44px);
        min-height: rem(44px);
        border-bottom: rem(2px) solid rgba(255, 255, 255, 0) !important;
        text-underline-offset: rem(3px) !important;
        transition: border 100ms ease-in-out;
        filter: none;

        &:hover,
        &:active,
        &:focus {
            // Please forgive us gods of the internet
            outline: none;
            background-color: transparent;
        }

        &.active {
            border-bottom: rem(2px) solid rgba(255, 255, 255, 1) !important;
            text-underline-offset: rem(3px) !important;
        }

        &:first-child {
            margin-left: 2rem;
        }

        &:first-of-type {
            margin-right: auto;
        }

        &:not(:last-of-type) {
            margin-right: rem(30px);
        }

        @media (orientation: landscape) {
            padding-bottom: rem(24px);

            span {
                letter-spacing: rem(1px);
            }

            &:first-child {
                margin-left: 0;
            }

            &:first-of-type {
                padding-left: 0;
            }

            &:not(:last-of-type) {
                margin-right: rem(40px);
            }
        }
    }
}

.slider {
    display: flex;
    max-width: rem(1300px);
    /* 602 img height + 100 whitespace*/
    min-height: rem(700px);
    /* leave room for <Draggable/> */
    transform: translate(0, rem(80px));

    @media (orientation: landscape) {
        /* Go over next component */
        transform: translate(0, rem(120px));
    }
}

.item {
    display: flex;
    flex: 0 0 100% !important;
    flex-direction: column;
    max-width: rem(1100px);
    margin-left: auto;
    margin-right: auto;

    @include bp(lg) {
        flex-direction: row;
        flex: 0 0 93% !important;
        padding-left: 4.3%;
    }
}

.pic {
    position: relative;
    width: 80%;
    max-width: rem(481px);
    width: clamp(60%, 75%, rem(481px));
    height: 100vw;
    max-height: rem(502px);
    margin-bottom: rem(20px);
    transition: filter 100ms ease-in-out;
    @include bp(lg) {
        width: rem(481px);
        margin-bottom: 0;
        max-height: rem(630px);
    }
}

.dimmed {
    filter: brightness(0.4);
}

.card {
    color: var(--utmb-color-white);
    flex: 1;
    line-height: 1.6;
    padding: rem(40px) rem(70px) rem(80px) rem(30px);
    min-width: min(90%, rem(360px));
    @include bp(md) {
        & {
            padding: rem(140px) rem(60px) rem(40px) rem(60px);
            max-width: rem(520px);
        }
    }
}

.card_content {
    @include bp(lg) {
        width: 85%;
    }
}

/* default embla_buttons have a rem(45px) and rem(90px) width */
.prev,
.next {
    position: absolute;
    z-index: 99;
}
.prev:active,
.next:active,
.prev:visited,
.next:visited,
.prev:focus,
.next:focus {
    outline: -webkit-focus-ring-color auto rem(4px) !important;
}

.prev {
    bottom: rem(-25px);
    left: rem(20px);
}
.next {
    bottom: rem(-25px);
    right: calc(100% + 10%);
}
@media (orientation: landscape) {
    .prev {
        top: rem(-40px);
        left: rem(-10px);
    }
    .next {
        top: rem(-40px);
        // Original: rem(-45px)
        right: calc(100% - rem(25px));
        bottom: auto;
    }
}

.button {
    display: flex !important;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: rem(20px);
}
